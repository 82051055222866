import English from "@/utillities/languages/English";

export default {
  ...English,
  enter_in_minutes: "Ingresar en minutos",
  custome_fields: "Campos personalizados",
  enter_data_in_minutes: "Ingresar datos en minutos",
  enter_data_in_hours: "Ingresar datos en horas",

  create_tod_billing_widget: "Crear widget de facturación TOD",
  edit_tod_billing_widget: "Editar widget de facturación TOD",
  choose_from_machine_groups: "Elegir de grupos de máquinas",
  tod_billing: "Facturación TOD",
  choose: "Elegir",

  create_shift_based_machine_usage_log: "Crear registro de uso de máquinas",
  shift_based_machine_usage_log: "Registro de uso de máquinas",
  rejected: "Rechazado",
  machine_operator: "Operador de máquina",
  production_end_timestamp: "Hora de finalización de producción",
  production_start_timestamp: "Hora de inicio de producción",
  running_time: "Tiempo de funcionamiento",
  idle_time: "Tiempo de inactividad",
  down_time: "Tiempo de inactividad",

  date_row_index: "Fila de fecha",
  date_position: "Posición de la fecha",
  language: "idioma",

  safety_incident_sheet: "Hoja de incidente de seguridad",
  safety_inspection_sheet: "Hoja de inspección de seguridad",

  ghg_emission_logs: "Registros de emisiones de GEI",
  add_ghg_emission_data: "Agregar registros de emisiones de GEI",
  ghg_scope_conversion: "Conversión de alcance de GEI",
  create_ghg_scope_conversion: "Crear conversión de alcance de GEI",
  ghg_data_logs: "Registros de datos de emisiones de GEI",

  choose_inspection_type_optional: "Elegir tipo de inspección (Opcional)",

  supplier_code: "Código de proveedor",
  contact: "Contacto",
  point_of_contact: "Punto de contacto",
  quantity_supplied: "Cantidad suministrada",
  create_product: "Crear producto",
  products: "Productos",
  data: "Datos",
  timezone: "Zona horaria",
  tariffs: "Aranceles",

  days_delta: "Delta de días",
  machine_groups: "Grupos de máquinas",
  param_groups: "Grupos de parámetros",
  user_groups: "Grupos de usuarios",

  topic_prefix: "Prefijo del tema",
  message_direction: "Dirección del mensaje",

  safety_inspection: "Inspección de seguridad",
  write_inspection_log: "Escribir registro de inspección",
  inspection_id: "ID de inspección",
  inspector_id: "ID del inspector",
  inspection_date: "Fecha de inspección",
  inspection_type: "Tipo de inspección",
  findings: "Hallazgos",
  actions_required: "Acciones requeridas",
  followup_date: "Fecha de seguimiento",
  status: "Estado",

  safety_incident: "Incidente de seguridad",
  write_incident: "Escribir incidente",
  incident_description: "Descripción del incidente",
  severity: "Severidad",
  accident: "Accidente",
  accident_with_loss: "Accidente con pérdida",
  closed: "Cerrado",
  corrective_action: "Acción correctiva",
  employee_id: "ID del empleado",
  apply: "Aplicar",
  choose_location_optional: "Elegir ubicación (Opcional)",

  create_tariff: "Crear Tarifa",
  default_tariff_val: "Valor de Tarifa Predeterminado",
  add_tariff_data: "Agregar Datos de Tarifa",
  edit_tariff: "Editar Tarifa",
  delete_tariff: "Eliminar Tarifa",
  tariff_value: "Valor de Tarifa",

  machine_status_timeline: "Cronología del estado de la máquina",
  total_duration: "Duración total",
  generate: "Generar",
  remove_machine: "Eliminar máquina",
  edit_machine_status_timeline_widget: "Editar widget de la línea de tiempo del estado de la máquina",
  add_machine_Status__timeline_widget: "Agregar widget de la línea de tiempo del estado de la máquina",
  select_timeframe: "Seleccionar período",
  timeframe_type_shift: "Turno",
  timeframe_type_custom: "Personalizado",
  timeframe_type_yesterday: "Ayer",
  edit_report_details: "Editar información",
  download_current_template: "Descargar Plantilla actual",
  date_column_index: "Columna de fecha (Index)",
  select_area: "Seleccionar área",
  select_shift: "Seleccionar turno",

  dashboard_heading: "Dashboard",
  production_params: "Parámetros de producción",
  no_modules_configured: "No hay módulos configurados",
  dashboard_panel: "Panel de panel",
  choose_type: "Elija Tipo",
  name_is_required: "Nombre es obligatorio",
  description_is_required: "Descripción es necesaria",
  required: "Necesidades",
  you_have_to_select_something: "Tienes que seleccionar algo",
  name_must_be_less_than_30_characters:
    "El nombre debe ser inferior a 30 caracteres",
  name_must_be_less_than_25_characters:
    "El nombre debe ser inferior a 25 caracteres",
  name_must_be_less_than_50_characters:
    "El nombre debe ser inferior a 50 caracteres",
  description_must_be_less_than_35_characters:
    "Descripción debe ser inferior a 35 caracteres",
  description_must_be_less_than_55_characters:
    "Descripción debe ser inferior a 55 caracteres",
  number_must_be_less_than_6_digits: "El número debe ser menor a 6 dígitos",
  number_must_be_less_than_8_digits: "El número debe ser menor Que 8 dígitos",
  number_must_be_less_than_7_digits: "El número debe ser menor Que 7 dígitos",
  panel: "Panel",
  gauge: "Gauge",
  param: "Param",
  machine: "Máquina",
  add_panel_to_dashboard: "Añadir Panel a Dashboard",
  edit_dashboard_panel: "Editar Panel Dashboard",
  param_type: "Tipo de paradigma",
  workspace: "Espacio de trabajo",
  device: "Dispositivo",
  name: "Nombre",
  time_duration: "Duración del tiempo",
  submit: "Submit",
  cancel: "Cancelar",
  close: "Cerca",
  usage: "Usage",
  peak_value: "Valor de pico",
  current_data: "Datos actuales",
  parameter: "Parámetro",
  computed_param: "Computed Param",
  derived_param: "Derived Param",
  last_7_days: "Últimos 7 Días",
  last_30_days: "Los últimos 30 días",
  last_60_days: "Los últimos 60 días",
  last_90_days: "los últimos 90 días",
  last_180_days: "Últimos 180 días",
  last_365_days: "Lat 365 Días",
  last_1_hour: "última hora",
  last_3_hours: "últimas 3 horas",
  last_6_hours: "las últimas 6 horas",
  reorder_views: "Reordenado",
  create_view: "Crear",
  clear_view_name_filter: "Filtros claros",
  view_type: "Tipo de vista",
  description: "Descripción",
  table_view: "Cuadro View",
  widget_view: "Widget View",
  dynamic_timeframed_widget_view: "Dynamic Timeframed Widget Ver",
  diagram_view: "Vista del diagrama",
  energy_usage_view: "Energy Usage View",
  machine_scroll_view: "Máquina de tracción Vista",
  param_scroll_view: "Pergamino de Param Vista",
  consolidated_view: "Vista consolidada",
  air_quality_view: "Vista de calidad del aire",
  view_auto_change: "Ver cambio automático",
  no_views_configured: "No Views Configured",
  add_to_dashboard: "Añadir a Dashboard",
  edit_view_name: "Editar nombre",
  edit_view: "Editar nom",
  save: "Ahorrar",
  discard: "desechar",
  adjust: "ajustar",
  refresh: "Refresh",
  create_machine: "Crear máquina",
  add_machine: "Añadir máquina",
  sequence: "Secuencia",
  machine_type: "Tipo de máquina",
  physical_machine: "Máquina física",
  virtual_machine: "Máquina virtual",
  positive_workspace: "Espacio de trabajo positivo",
  positive_machine: "Máquina positiva",
  negative_workspace: "Espacio de trabajo negativo",
  negative_machine: "Máquina negativa",
  label: "Label",
  positive_machine_Ids: "Máquina positiva ID",
  negative_machine_Ids: "Máquina negativa ID",
  trend_analysis_tools: "Herramientas de análisis de tendencias",
  energy_consumption_by_machines: "Energy Consumption Por Máquinas",
  Create_report: "Crear informe",
  view_report: "Ver informe",
  tarrif_on: "Tarrif On",
  active_energy: "Energía activa",
  apparent_energy: "Apparent Energy",
  automate_report: "Automate Report",
  add_root_machine: "Añadir la máquina de arranque",
  add_common_machine: "Añadir la máquina común",
  notification_groups: "Grupos de notificación",
  add_machines_to_list: "Añadir máquinas a la lista",
  machine_list: "Lista de máquinas",
  data_historian: "Data Historian",
  timeframed_trends: "Tendencias de tiempo",
  dual_axis_trend_comparison: "Comparación de tendencias de eje dual",
  granular24_hrs_trend: "Granular24 Hrs Trend",
  machinewise_trend: "Tendencia de alambre de la máquina",
  basic_statistical_trends: "Estadísticas básicas Tendencias",
  log_analysis: "Análisis de registros",
  aqi_comparison_panel: "AQI Comparison Panel",
  time_of_day_trend: "Tiempo del día Trend",
  param_historical_comparisons: "Comparaciones históricas de Param",
  energy_usage_trends: "Energy Usage Trends",
  machine_name: "Nombre de la máquina",
  param_name: "Nombre del paradigma",
  trend_view: "Vista de tendencias",
  raw: "Raw",
  trend: "Trend",
  normalised: "Normalizado",
  from_date: "Fecha",
  to_date: "Fecha",
  from: "Desde",
  to: "A",
  hour: "Hora",
  minute: "Minute",
  generate_report: "Generar informe",
  time_range: "Rango de tiempo",
  no_data_available: "No se dispone de datos",
  daily: "Diario",
  monthly: "Monthy",
  week: "Seman",
  yearly: "Año",
  device_name: "Nombre del dispositivo",
  machine_wise_comparison: "Comparación de alambre de máquina",
  stats_detailed_report: "Estadísticas Informe detallado",
  log_type: "Tipo de registro",
  choose_Log: "Elija Log",
  area: "Zona",
  aq_device: "Dispositivo AQ",
  nebeskie_labs_office: "Nebeskie Labs Office",
  date_range: "Date_Range",
  this_year: "Este año",
  "last 6 months": "Los últimos 6 meses",
  "last quater": "Último Quater",
  mtd: "MTD",
  ytd: "YTD",
  yesterday: "Ayer",
  custom: "Aduanas",
  run_report: "INFORME DE RUN",
  create_report: "Crear informe",
  usage_report: "Usage Report",
  consolidated_report: "Informe consolidado",
  dynamic_excel_report: "Informe dinámico de Excel",
  energy_usage_report: "Energy Usage Report",
  create_action: "Crear acción",
  action_type: "Tipo de acción",
  notification_group: "Grupo de notificación",
  address: "Dirección",
  number_of_registers: "Número de registros",
  value: "Valor",
  send_notification: "Enviar notificación",
  write_parameter: "Parámetro",
  relays: "Relays",
  relay_1: "Relé 1",
  relay_2: "Relé 2",
  relay_3: "Relé 3",
  relay_4: "Relé 4",
  email_must_be_valid: "Correo electrónico debe ser válido",
  it_should_be_a_number: "Debería ser un número",
  it_should_be_a_valid_number: "Debería ser un número válido",
  operator: "Operador",
  operand: "Operand",
  target_value: "Valor de destino",
  wait_time_seconds: "Hora de espera (segundos)",
  greater_than: "Más grande que",
  modulus_greater_than: "Modulus Greater Than",
  lesser_than: "Menos",
  modulus_lesser_than: "Modulus Lesser Than",
  equal_to: "Igualdad",
  not_equal_to: "No es igual",
  modulus_equal_to: "Modulus Equal To",
  greater_than_equal_to: "Mayor Than Equal",
  modulus_greater_than_equal_to: "Modulus Greater Than Equal To",
  lesser_than_equal_to: "Menor que igual",
  modulus_lesser_than_equal_to: "Modulus Lesser Than Equal To",
  bit_high: "Bit High",
  bit_low: "Bit Low",
  it_should_be_a_positive_whole_number: "Debe ser un número entero positivo",
  it_should_be_a_positive: "Debería ser un positivo",
  create_process: "Crear proceso",
  edit_process: "Proceso de edición",
  status_rule: "Estado",
  create_process_status_rule: "Crear estado de proceso",
  tag_type: "Tipo de etiqueta",
  process_status: "Estado del proceso",
  machines: "Máquinas",
  running: "Corriendo",
  idling: "Idling",
  offline: "Sin conexión",
  no_machines_configured: "Sin máquinas configuradas",
  delete_premise_area: "Eliminar área del local",
  delete_process: "Eliminar el proceso",
  add_machine_to_process: "Añadir la máquina al proceso",
  add: "ADD",
  add_to_list: "Añadir a la lista",
  search: "Búsqueda",
  switch_to: "Cambiar a",
  switch_to_base_org: "Interruptor a org base",
  switch: "Cambio",
  create_new_organisation: "Crear nueva organización",
  organisation_name: "Nombre de la organización",
  organisation_type: "Tipo de organización",
  country: "país",
  country_code: "código de país",
  state: "Estado",
  clear: "Despejado",
  change_organisation: "Change Organisation",
  organisation_id: "Organisation ID",
  update_your_oem_logo: "Actualice su logotipo OEM",
  select_a_logo_to_upload: "Seleccione un logotipo para cargar",
  submit_logo: "Submit Logo",
  create_workspace: "Crear espacio de trabajo",
  hub_id: "HUB ID",
  name_must_be_less_than_45_characters:
    "El nombre debe ser menos de 45 caracteres",
  id_is_required: "ID es obligatorio",
  no_space_allowed: "No se permite espacio",
  id_must_be_less_than_6_characters: "Id debe ser menos de 6 caracteres",
  edit_workspace: "Editar espacio de trabajo",
  delete_workspace: "Eliminar el espacio de trabajo",
  add_modbus_device: "Agregar dispositivo Modbus",
  add_device: "Agregar dispositivo",
  oops: "¡Ooops!",
  ok: "Ok",
  slave_id: "Identificación de esclavos",
  device_type: "Tipo de dispositivo",
  connection_type: "Tipo de conexión",
  mGate: "m Puerta",
  make: "Hacer",
  model: "Modelo",
  name_must_be_less_than_33_characters:
    "El nombre debe ser inferior a 33 caracteres",
  name_must_be_less_than_11_characters:
    "El nombre debe ser inferior a 11 caracteres",
  it_should_be_a_valid_slave_id:
    "Debe ser una identificación válida de esclavos",
  name_must_be_less_than_4_digits: "El nombre debe ser inferior a 4 dígitos",
  modbus_tcp: "Modbus TCP",
  modbus_rtu: "Modbus RTU",
  add_virtual_device: "Agregar dispositivo virtual",
  add_mqtt_device: "Agregar dispositivo MQtt",
  topic: "Tema",
  identifier: "Identifier",
  identifier_value: "Valor identificador",
  name_must_be_less_than_20_characters:
    "El nombre debe ser inferior a 20 caracteres",
  no_special_characters_or_space_allowed:
    "No hay caracteres especiales o espacio permitido",
  must_be_less_than_15_characters: "Debe Ser menos de 15 charcteres",
  create_role: "Crear papel",
  role: "Función",
  role_is_required: "El papel es necesario",
  role_must_be_less_than_40_characters:
    "El papel debe ser inferior a 40 caracteres",
  role_should_not_contain_white_characters:
    "El papel no debe contener caracteres blancos",
  comparison: "Comparación",
  historical_time_of_day_comparison_parameter:
    "Tiempo de historia Comparación del día - Parámetro",
  device_parameter: "Parámetro de dispositivo",
  date_1: "Fecha 1",
  date_2: "Fecha 2",
  tod_comparison_report: "TOD Comparison Report",
  time_frame: "Time Frame",
  download_table: "Descargar Tabla",
  table: "Cuadro",
  showMinMax_values: "Mostrar valores Min & Max",
  usage_or_average: "Uso o promedio",
  min_value: "Valor mínimo",
  last_12_weeks: "Las últimas 12 semanas",
  last_6_months: "Los últimos 6 meses",
  last_12_months: "Últimos 12 meses",
  year_5: "5 años",
  machinewise_comparison: "Comparación inteligente de la máquina",
  timeFrame: "Timeframe",
  it_should_be_a_date: "Debe ser una cita",
  time_of_day_parameter: "Tiempo del día- Parámetro",
  tod_report: "TOD - Informe",
  in_this_timeframe: "En este marco temporal",
  average: "Promedio",
  maximum: "Máximo",
  minimum: "Mínimo",
  energy_usage_trend: "Energy Usage Trend",
  standard_deviation: "Desviación estándar",
  statistical_mode: "Modo estadístico",
  production_logs: "Registros de producción",
  capacity_logs: "Logros de capacidad",
  dynamic_logs: "Registros dinámicos",
  pollutants: "Contaminantes",
  group_by: "Grupo By",
  generate_grouped_trends: "Generate Grouped Tendencias",
  tCo2: "tCo2",
  scope_1: "Ámbito 1",
  scope_2: "Ámbito 2",
  scope_3: "Alcance 3",
  source: "Fuente",
  type: "Tipo",
  user_access_log: "User Access Log",
  user_logs: "User Logs",
  fetch_logs: "Registros de captura",
  update_profile: "Perfil de actualización",
  lang: "Lang",
  theme: "Tema",
  phone: "Teléfono",
  update: "Actualización",
  update_password: "Actualizar contraseña",
  change_password: "Cambiar contraseña",
  old_password: "Old Password",
  new_password: "Nueva contraseña",
  confirm_password: "Confirmar contraseña",
  password_must_be_more_than_8_characters:
    "Contraseña debe ser más de 8 caracteres",
  passwords_do_not_match: "Las contraseñas no coinciden",
  add_hub: "Agregar Hub",
  id_must_be_less_than_4_characters: "ID debe ser inferior a 4 caracteres",
  add_maintainer: "Add Maintainer",
  add_parent_org: "Add Parent Org",
  add_parent_to_organisation: "Add Parent to Organisation",
  parent_org_id: "Parent Org ID",
  add_maintainer_to_organization: "Add Maintainer to Organization",
  maintainer_id: "ID de mantenimiento",
  update_organisation_details: "Información actualizada de la organización",
  update_your_organization_details:
    "Actualizar los detalles de su organización",
  default_email: "Correo electrónico predeterminado",
  picker_in_menu: "Picker in Menu",
  param_diagnostics: "Diagnósticos de Param",
  show_logo: "Mostrar logo",
  show_oem_logo: "Mostrar logo OEM",
  organisation_alias: "Organisation Alias",
  update_organisation_alias: "Organización de actualización Alias",
  update_org_alias: "Actualización Org Alias",
  update_your_organization_logo: "Actualice su organización Logotipos",
  select_a_logo: "Seleccione un logotipo",
  update_logo: "Logo Actualización",
  create_uam_user: "Crear UAM Usuario",
  overview: "Sinopsis",
  batches: "Batches",
  track_batch: "Pista Batch",
  batch_movement: "Batch Movement",
  production_planner: "Planificador de producción",
  show_unshipped_only: "Mostrar un solo envío",
  view_logs: "Ver los registros",
  params: "Params",
  add_filter: "Agregar filtro",
  filters: "Filtros",
  batch_id: "Batch_id",
  show_details: "Mostrar detalles",
  tracing_logs: "Tracing Logs",
  quantity: "Cantidad",
  location_event_type: "Lugar Tipo de evento",
  fetch_plans: "Planes de embrague",
  sku: "SKU",
  code: "Código",
  add_production_plans: "Añadir planes de producción",
  date: "Fecha",
  from_time: "Desde el momento",
  to_time: "Al tiempo",
  activities: "Actividades",
  task_name: "Nombre de la tarea",
  task_details: "Detalles de la tarea",
  start_date: "Fecha de inicio",
  end_date: "Fecha final",
  assignee: "Assignee",
  manager: "Manager",
  activity: "Actividad",
  activity_type: "Tipo de actividad",
  escalation_matrix: "Matriz de escalada",
  maintenance_activity: "Actividad de mantenimiento",
  em_level: "Nivel EM",
  delay: "Delay",
  user_group: "Grupo de usuarios",
  mins_5: "5 minutos",
  mins_10: "10 minutos",
  mins_15: "15 minutos",
  mins_30: "30 minutos",
  hour_1: "1 hora",
  hour_6: "6 horas",
  day: "día",
  day_3: "3 días",
  insights: "Insights",
  create_emailer_group: "Crear Emailer Group",
  notification_type: "Tipo de notificación",
  email: "Email",
  sms: "SMS",
  create_machine_group: "Crear Grupo de Máquinas",
  create_param_group: "Crear Grupo Param",
  create_user_group: "Crear Grupo de Usuarios",
  create_module: "Crear módulo",
  busbar_temp_module: "Módulo BusBar Temp",
  power_quality_module: "Módulo de calidad de potencia",
  esg_module: "ESG Módulo",
  edge_hub: "Edge Hub",
  hub: "Hub",
  tolerance: "Tolerancia",
  ambient_temp_input_type: "Tipo de entrada de temperatura ambiente",
  ambient_temp: "Temperatura ambiente",
  workspace_ambient_temp: "Espacio de trabajo Temperatura ambiente",
  device_ambient_temp: "Temperatura del dispositivo",
  r_temperature: "R Temperatura",
  y_temperature: "Y Temperatura",
  b_temperature: "B Temperatura",
  n_temperature: "N Temperatura",
  rated_current: "Rated Current",
  no_load_current: "No Carga actual",
  r_current: "Corriente",
  y_current: "Y Corriente",
  b_current: "B Corriente",
  n_current: "N Current",
  delete_activity: "Eliminar la actividad",
  substation_voltage_kv: "Substation Voltage(KV)",
  transformer_rating_mva: "Transformer Rating(MVA)",
  percentage_impedance_of_the_transformer: "% Impedancia del Transformador",
  voltage_rv_y: "Voltaje RV(Y)",
  voltage_yb_v: "Voltaje YB(V)",
  voltage_br_v: "Voltaje BR(V)",
  current_r_a: "Actual R(A)",
  current_y_a: "Y(A) actual",
  current_b_a: "Actual B(A)",
  thd_i: "THD I",
  thd_v: "THD V",
  thd_i1: "THD I1",
  active_power_kw: "Potencia activa (kw)",
  apparent_power_kva: "Potencia aparente (KVA)",
  reactive_power_kvar: "Potencia reactiva (KVAR)",
  active_energy_kwh: "Energía activa (KWH)",
  apparent_energy_kvah: "Energía aparente (KVAH)",
  pf: "PF",
  text: "Texto",
  energy_source: "Energy Source",
  scope: "Ámbito",
  system_logs: "Logros del sistema",
  system_logbook: "Libro de registro del sistema",
  esg_data_log: "ESG Registro de datos",
  add_esg_data: "Añadir ESG Datos",
  add_esg_data_log: "Añadir ESG Registro de datos",
  source_type: "Tipo de fuente",
  unit_of_measurement: "Unidad de medición",
  from_date_optional: "Desde la fecha (Opcional)",
  "emission_factor": "Factor de emisión (kgCO2)",
  create_dynamic_logger: "Crear Logger dinámico",
  dynamic_log: "Registro dinámico",
  primary_field_label: "Etiquetas primarias del campo",
  primary_field_type: "Tipo de campo primario",
  primary: "Primaria",
  additional_fields: "Campos adicionales",
  field_label: "Field Label",
  field_type: "Tipo de campo",
  choose_list: "Seleccione la lista",
  list: "Lista",
  create_capacity_logger: "Crear el registrador de capacidades",
  capacity_log: "Capacity Log",
  create: "Crear",
  create_production_logger: "Crear un registrador de producción",
  production_log: "Registro de producción",
  production_logger: "Logger de producción",
  input_type: "Tipo de entrada",
  input: "Input",
  input_name: "Nombre de entrada",
  output_type: "Tipo de producto",
  output: "Producto",
  output_name: "Nombre del producto",
  output_params: "Parámetros de productos",
  compute_production: "Compute Production",
  compute_energy: "Compute Energy",
  energy_param_type: "Energy Param Type",
  energy_device: "Energy Device",
  energy_param: "Energy Param",
  production_operator: "Operador de Producción",
  production_correction_factor: "Factor de Corrección de Producción",
  output_operator: "Operador de Productos",
  output_correction_factor: "Factor de corrección de productos",
  input_operator: "Input Operator",
  input_correction_factor: "Factor de corrección de productos",
  energy_unit: "Energy Unit",
  production_unit: "Dependencia de Producción",
  production: "Producción",
  create_data_logger: "Crear registrador de datos",
  data_log: "Registro de datos",
  add_param: "Agregar Param",
  param_id: "ID de Param",
  create_batch_production_logger: "Crear logger de producción de lotes",
  batch_tracing_log: "Batch Tracing Log",
  log_name: "Nombre del registro",
  location: "Ubicación",
  info: "Info",
  remarks: "Observaciones",
  code_will_be_available_to_log_by_default:
    "el código estará disponible para iniciar sesión por defecto",
  fields: "Campos",
  additional_info: "Información adicional",
  action: "Medida",
  report_type: "Tipo de informe",
  create_parameter_report: "Crear informe del parámetro",
  data_processing: "Procesamiento de datos",
  addition: "Adición",
  singular: "Singular",
  report: "Informe",
  energy_parameters_kwh: "Parámetros de energía (KWH)",
  apparent_energy_KVAh: "Apparent Energy KVAh",
  maximum_demand_MD: "Demanda máxima (MD)",
  excel_frequency: "Excel Frequency",
  multiplier: "Multiplicador",
  single: "Individual",
  incomers: "Ingresos",
  sections: "Secciones",
  energy_distribution_report: "Energy Distribution Informe",
  root_machine: "Máquina rotatoria",
  tariff_on: "Tarrif On",
  add_root_common_machine: "Añadir raíz & máquina común",
  common_machine: "Máquina común",
  energy_bill_distribution_report: "Energy Bill Distribution Report",
  create_consolidated: "Crear consolidación",
  choose_report: "Seleccionar informe",
  id: "Id",
  timeframed_sec: "Time Framed Sec",
  create_dynamic_excel_report: "Crear informe dinámico de Excel",
  update_dynamic_excel_report: "Actualización Informe dinámico de Excel",
  add_sheet: "Add Sheet",
  sheet_name: "Nombre de la hoja",
  timestamp: "Timestamp",
  sheet: "Sheet",
  sheets: "Hojas",
  sheet_id: "Hoja de identificación",
  delete_sheet: "Suprímase la hoja de cálculo",
  report_name: "Nombre del informe",
  update_details: "Información actualizada",
  unit: "Dependencia",
  parameters: "Parámetros",
  send_excel_file: "Enviar archivo Excel",
  update_excel_template_based_report:
    "Informe de actualización basado en la plantilla de Excel",
  create_excel_template_based_report:
    "Crear informe basado en plantillas de Excel",
  upload_excel_template_xlsx: "Subir la plantilla de Excel (xlsx)",
  param_equation: "Param Equation",
  edit_sheets: "Editar hojas de cálculo",
  row_no: "Row",
  column_no: "Columna No",
  row_number: "Número de filas",
  column_number: "Columna Número",
  colummn_number: "Columna Número",
  no_sheets_added: "No se han añadido hojas",
  process_based_usage_sheet: "Hoja de uso basada en procesos",
  process: "Proceso",
  process_label: "Etiquetas del proceso",
  add_tag_types: "Añadir Tipos de etiqueta",
  add_process_to_list: "Agregar proceso a la lista",
  machine_tag_based_daily_data_sheet: "Tag Based Daily Datos Sheet",
  machine_label: "Máquina de etiqueta",
  add_machine_to_list: "Añadir la máquina a la lista",
  add_tag_type_to_list: "Añadir Tipo de etiqueta a la lista",
  shift_based_usage_sheet: "Hoja de uso basada en robos",
  status_for_process: "Proceso",
  status_for_machine: "Máquina",
  esg_summary_item: "Resumen",
  esg_details_item: "Detalles",
  esg_scope1_label: "Ámbito 1",
  esg_scope2_label: "Ámbito 2",
  esg_scope3_label: "Alcance 3",
  esg_scopes_list_label: "Scopes",
  transpose: "Transpose",
  process_based_timeframed_data_sheet:
    "Calendario basado en procesos Hoja de datos",
  add_pocess_to_list: "Agregar proceso a la lista",
  tag_types: "Tipos de etiqueta",
  " consolidated_shift_based_usage_data_sheet":
    "Hoja de datos de uso basado en un cambio consolidado",
  date_format: "Date Format",
  include_process_total: "Total del proceso",
  machine_group_based_usage_sheet: "Hoja de uso basada en grupos de máquinas",
  machine_group_label: "Etiquetas del grupo de máquinas",
  machine_group: "Grupo de máquinas",
  add_machine_group_to_list: "Añadir Grupo de máquinas a la lista",
  parameter_based_usage_data_sheet: "Hoja de datos de uso basado en parámetros",
  "show_month_start&end_data": 'Mostrar el mes Inicio " Datos finales',
  statistics: "Estadísticas",
  add_param_to_list: "Añadir Param a la lista",
  add_timestamp_to_list: "Añadir Timetamp To List",
  parameter_group_based_timeframed: "Parámetro de tiempo basado en el grupo",
  param_group_label: "Param Group Label",
  param_group: "Param Group",
  add_param_group_to_list: "Agregar Grupo Param a lista",
  dynamic_logger_based_data_sheet: "Dinámico Logger Datos basados Sheet",
  dynamic_log_label: "Dynamic Log Label",
  parameter_equation_based_sheet: "Hoja de cálculo del parámetro",
  data_source: "Fuente de datos",
  equation_label: "Equation Label",
  add_equation_to_list: "Añadir Ecuación a la lista",
  absolute_values: "Valores absolutos",
  consumption_based_sheet: "Hoja Basada en Consumo",
  parameter_name: "Nombre del parámetro",
  statistical_analysis_based_sheet: "Hoja de análisis estadístico",
  parameter_instantaneous_data_sheet: "Parámetro Instantáneo Hoja de datos",
  create_sheet_column: "Crear Hoja Columna",
  column_label: "Columna Label",
  machine_id: "Identificación automática",
  create_machine_list: "Crear lista de máquinas",
  create_param_list: "Crear parámetro Lista",
  create_sheets: "Crear hojas de cálculo",
  create_shift: "Crear Shift",
  description_optional: "Descripción (opcional)",
  create_sku: "Crear SKU",
  create_location: "Crear ubicación",
  location_type: "Tipo de ubicación",
  from_location: "Desde Ubicación",
  to_location: "A Localización",
  event_type: "Tipo de evento",
  create_location_event_type: "Crear ubicación tipo de evento",
  create_list: "Crear lista",
  create_param_equation: "Crear Ecuación de Param",
  positive_params: "Params positivos",
  params_to_be_substracted: "Parámetros que han de subcontratar",
  create_combo_param: "Crear Combo Param",
  inter_param_operator: "Inter Param Operator",
  correction_operator: "Correction Operator",
  correction_operand: "Operand de Corrección",
  create_calculated_param: "Crear Param calculado",
  calculated_param: "Parámetro calculado",
  correction_factor: "Factor de corrección",
  param1_type: "Param 1 Tipo",
  param1: "Párrafo 1",
  param1_operator: "Operador de Param 1",
  param1_operand: "Param 1 Operand",
  param2_type: "Param 2 Tipo",
  param2: "Párrafo 2",
  param2_operator: "Param 2 Operador",
  param2_operand: "Param 2 Operand",
  create_esg_scope_conversion: "Crear ESG Conversión de Scope",
  esg_scope_logging: "Registro de alcance ESG",
  targets: "Objetivos",
  category: "Categoría",
  get_from_param: "Obtener de Param",
  choose_param: "Elija Param",
  create_target: "Crear objetivo",
  create_word: "Crear palabra",
  words: "Palabras",
  choose_OEM: "Elija OEM",
  get_the_models: "Obtener los modelos",
  get_oem_devices: "Obtener OEM Dispositivos",
  oem_devices: "Dispositivos OEM",
  model_number: "Número de modelo",
  register: "Registro",
  add_modbus_gateway: "Añadir Modbus Gateway",
  " add_modbus_TCP_gateway": "Añadir entrada Modbus TCP",
  IPAddress: "Dirección IP",
  port_number: "Número de puerto",
  HubID: "Hub ID",
  baud_rate: "Tasa de Baud",
  parity: "Paridad",
  stop_bit: "Para",
  update_wifi: "Actualizar Wifi",
  SSID: "SSID",
  password: "Contraseña",
  restart_enture: "Restart Enture",
  force_update_configs: "Configs de actualización de la fuerza",
  activity_name: "Nombre de la actividad",
  board: "Junta",
  calender: "Calender",
  maintenance: "Mantenimiento",
  welcome: "Bienvenido",
  logout: "Cerrar sesión",
  help: "Ayuda",
  accounts: "Cuentas",
  organisation: "Organización",
  pointer_color_optional: "Color puntero (opcional)",
  user_id: "ID de usuario",
  confirm_new_password: "Confirme nueva contraseña",
  service: "Servicio",
  send_grid: "Enviar Grid",
  default_enture_view: "Vista de la entrada predeterminada",
  permissions: "Permisos",
  areas: "Zonas",
  remark: "Remark",
  days: "Días",
  "t4_days": "4_Días",
  yesterdays_aqi: "Ayer es AQI",
  aqi: "AQI",
  choose_air_quality_device: "Elija el dispositivo de calidad del aire",
  delete_this_device: "Eliminar este dispositivo",
  air_quality_label: "Air Quality Label",
  humidity: "Humedad",
  temperature: "Temperatura",
  watcher: "Watcher",
  area_reference: "Referencia del área",
  write_data: "Escriba datos",
  day_of_week: "Día de la Semana",
  month: "Mes",
  diagnostics_notification_groups: "Grupos de notificación de diagnóstico",
  hub_name: "Nombre del Hub",
  hub_model: "Modelo de Hub",
  hub_type: "Tipo de Hub",
  update_your_MQTT_bridge_config: "Actualice su MQTT Bridge Config",
  MQTT_bridge_mode: "MQTT Modo de puente",
  broker_address: "Broker Address",
  update_your_OPC_config: "Actualice su Config OPC",
  OPC_client_mode: "OPC Client Mode",
  OPC_server_URL: "OPC Server URL",
  user_auth: "Usuario Auth",
  mode: "Modo",
  security_policy: "Política de seguridad",
  Update_your_Edge_Hub_restart_Schedule:
    "Actualice su Edge Hub reinicio Horario",
  auto_restart: "Auto reinicio",
  time_24Hrs: "Hora (24 Hrs)",
  modbus_ping_latency: "Modbus Ping Latency",
  modbus_unhealthy_wait: "Modbus Unhealthy Wait",
  edit_written_log_book: "Editar libro de registro escrito",
  field_cant_change: "Este campo no puede cambiar",
  re_write: "Re Write",
  write_in_logbook: "escribir en logbook",
  write: "Escriba",
  download_template: "Descargar Plantilla",
  write_in_logbook_csv: "Escriba el libro de Log por .csv",
  upload_file: "Cargar archivo",
  batch: "Batch",
  move_batch: "Muévete",
  on: "On",
  off: "Fuera",
  fast_mode: "Modo rápido",
  batch_shipping_details: "Batch Shipping Detalles",
  vehicle_no: "Vehículos No",
  net_weight: "Peso neto",
  actual_weight: "Peso real",
  vendor: "Vendor",
  loaded_by: "Cargado por",
  verified_by: "Verificado por",
  document_no: "Documento No",
  multiple_machine_param: "Máquina múltiple & Params",
  map_paramid_paramequation_to_row:
    "Mapa Param ID's o Param Equations Para remar",
  add_current_date_to_sheet: "Añadir fecha actual a la hoja",
  create_parameter_from_template: "Crear parámetro De la Plantilla",
  selected_parameters_count: "Cuenta de parámetros seleccionados",
  restricted_user_access: "Acceso restringido al usuario",
  select_all_parameters: "Seleccione todos los parámetros",
  edit_angular_gauge_widget: "Editar Angular Gauge Widget",
  add_angular_gauge_widget: "Añadir Angular Gauge Widget",
  widget_label: "Widget Label",
  widget_width: "Widget Width",
  widget_height: "Widget Altura",
  param_label: "Param Label",
  choose_parameter: "El parámetro",
  processing: "Procesamiento",
  edit_banner_widget: "Editar Banner Widget",
  add_banner_widget: "Añadir Banner Widget",
  edit_bartd_widget: "Editar Bar TD Widget",
  add_bar_widget: "Añadir Bar Widget",
  yaxis_label: "Y - Etiqueta del eje",
  xaxis_label: "X - Etiqueta del eje",
  y1axis_label: "Y1 - Etiqueta del eje",
  y2axis_label: "Y1 - Etiqueta del eje",
  edit_dual_axis_bar_trend_widget: "Editar widget de tendencia de barra de doble eje",
  add_dual_axis_bar_trend_widget: "Agregar widget de tendencia de barra de doble eje",
  y1_graph_type: "Tipo de gráfico del eje Y1",
  y2_graph_type: "Tipo de gráfico del eje Y2",
  idealValue: "ideal Valor",
  idealLabel: "ideal Label",
  add_parameters: "Añadir Parámetros",
  add_equation: "Add Equation",
  add_params: "Agregar Params",
  enter_of_machines: "No de máquinas",
  edit_bar_trend_td_widget: "Editar Bar Trend TD Widget",
  add_bar_trend_td_widget: "Añadir Bar Trend TD Widget",
  graph_type: "Tipo de Gráfico",
  aggregation: "Aggregation",
  default_graph_type: "Tipo de Gráfico predeterminado",
  bar_mode: "Modo de barras",
  show_ideal_line: "Mostrar línea ideal",
  equations: "Ecuaciones",
  edit_bitwise_word_widget: "Editar Bitwise Word Widget",
  add_bitwise_word_widget: "Añadir Bitwise Word Widget",
  show_bit_status: "Mostrar Bit Status",
  edit_bullet_gauge_widget: "Editar Bullet Gauge Widget",
  add_bullet_gauge_widget: "Add Bullet Gauge Widget",
  reference_value_for: "Valor de referencia",
  gauge_type: "Tipo de Gauge",
  indicator_color: "Color de indicador",
  busbar_temperature_graph_widget: "Bus Bar Temperatura Graph Widget",
  busbar_module: "Módulo Bus Bar",
  edit_consumption_gauge_widget: "Editar Consumo Gauge Widget",
  add_consumption_gauge_widget: "Añadir Consumo Gauge Widget",
  linearGauge: "Gauge lineal",
  show_target: "objetivo",
  healthy_on_below_target: "Saludable por debajo de la meta",
  edit_consumption_pie_widget: "Editar Consumo Pie Widget",
  add_consumption_pie_widget: "Añadir Consumo Pie Widget",
  add_color_break_point: "Agregar punto de ruptura de color",
  custom_color: "Color personalizado",
  color: "Color",
  edit_heat_map_widget: "Editar mapa de calor Widget",
  add_heat_map__widget: "Agregar mapa de calor Widget",
  choose_equation: "Elija Ecuación",
  label_star: "Etiquetas*",
  color_star: "Color*",
  operator1_star: "Operadora 1*",
  value1_star: "Valor 1*",
  operator2_star: "Operador 2*",
  actions: "Acciones",
  condition: "Condiciones",
  status_color: "Status Color",
  text_view: "Texto Ver",
  edit_process_Status_widget: "Editar el estado del proceso Widget",
  add_process_Status_widget: "Agregar estado del proceso Widget",
  edit_machine_Status_widget: "Editar Widget de estado de la máquina",
  add_machine_Status_widget: "Add Machine Status Widget",
  choose_process: "Seleccione Procesos",
  choose_machine: "Elija Máquinas",
  table_header_label: "Table Header Label",
  edit_table_TD_widget: "Editar tabla TD Widget",
  add_table_TD_widget: "Add Table TD Widget",
  configure_table: "Cuadro de configuración",
  edit_target_based_trend_widget: "Editar el Widget basado en el destino",
  add_target_based_trend_widget: "Add Target Based Trend Widget",
  target: "Meta",
  target_line_label: "Target Line Label",
  edit_usage_score_card: "Editar Usage Scorecard Widget",
  add_usage_score_card: "Add Usage Scorecard Widget",
  compare_to: "Compare To",
  assign_color: "Asignar color",
  enter: "Entra",
  gauge_to_dashboard: "Gauge To Dashboard",
  widget_types: "Widget Tipos",
  from_value: "De valor",
  to_value: "Valor",
  machine_status: "Estado de la máquina",
  deviceA_type: "Tipo de dispositivo",
  templates: "Plantillas",
  add_triggers_to_parameter: "Añadir desencadenantes al parámetro",
  triggers: "Triggers",
  show_filter: "Mostrar filtro",
  view_id: "Ver ID",
  move_up: "Muévanse",
  move_down: "Muévete",
  multiplier_types: "Multiplier_types",
  energy_report: "Energy Report",
  add_machine_param: "Añadir Param de máquina",
  aliasing: "Aliasing",
  no_zero_read: "No hay lectura cero",
  remove_negative_entropy: "Quitar la Entropía Negativa",
  calculate_consumption: "Cálculo Consumo",
  disabled: "Discapacitados",
  limiter_value: "Valor de Limmiter",
  machine_scroll_view_page: "Máquina de tracción Página",
  create_mannual_parameter: "Crear parámetro masculino",
  restrict_user_access: "Restringir el acceso del usuario",
  add_device_parameter: "Agregar parámetro dispositivo",
  entropy: "Entropía",
  idle_latency: "Idle Latency",
  add_OPC_parameter: "Agregar parámetro OPC",
  node_id: "Node ID",
  byte_format: "Formato Byte",
  param_scroll_view_page: "Param Scroll Página",
  timeframed_sec_report: "Time framed SEC Report",
  prod_unit: "Dependencia de Prod",
  SEC_unit: "SEC Dependencia",
  energy_parameters: "Parámetros de energía",
  prod_param_type: "Prod Param Tipo",
  production_parameters: "Parámetros de producción",
  no_of_intervals: "No de Intervaciones",
  granular_data: "Datos granulares",
  csv: "CSV",
  delete_rule: "Artículo",
  enable_disable_all_parameters: "Activar/desactivar todos los parámetros",
  disable: "Inhabilitación",
  enable: "Habilitación",
  edit_computed_parameter: "Editar Parámetro Computado",
  quantization: "Cuantización",
  command: "Comando",
  register_size: "Tamaño del registro",
  consumer_ID: "Consumer ID",
  site_id: "ID del sitio",
  get_data: "Obtener datos",
  update_param_type: "Param de actualización Tipo",
  write_in_data_logbook: "Escriba en Data Logbook",
  daily_data: "Datos diarios",
  log_entry: "Entrada",
  enable_Scroll_mode: "Modo de tracción enable",
  multiple_areas: "Múltiples áreas",
  time_of_day: "Hora del día",
  capacity_logger: "Capacity Logger",
  provider: "Proveedor",
  total_sites_received_bills_MTD: "Total Sites Received Bills MTD",
  sites_yet_to_receive_bills: "Sitios aún para recibir facturas",
  total_sites: "Total Sites",
  sites_received_bills_MTD: "%Sites recibió facturas MTD",
  total: "Total",
  total_bills_received_mtd: "Total de proyectos de ley recibidos",
  arrear_1: "Arrear 1",
  arrear_2: "Arrear 2",
  no_reading: "No leyendo",
  bills_received_in_5_days: "Facturas recibidas en los últimos 5 días",
  site_wise_status: "Sitio de estado sabio de Otro estado de OK",
  site_wise_final_summary: "Sitio Wise Resumen final",
  source_link: "Enlace de fútbol",
  data_type: "Tipo de datos",
  select_an_image_to_upload: "Seleccione una imagen para subir",
  user_group_Id: "ID de grupo de usuarios",
  user_group_name: "Nombre del Grupo de Usuario",
  delay_in_mins: "Delay in (Minutes)",
  delete_task: "Suprímase la tarea",
  watchers: "Vigilantes",
  comments: "Comentarios",
  asset_under_maintenance: "Asset Under Maintenance",
  start_time: "Hora de inicio",
  end_time: "Hora final",
  download_latest_usage_table: "Descargar la última tabla de uso",
  tag: "Tag",
  add_target_data: "Add Target Data",
  edit_target: "Objetivo",
  delete_target: "Meta",
  prop: "Prop",
  excel_template_based_report: "Excel Template Based Report",
  configured_hub: "Hub configurado",
  configured_relay: "Relé configurado",
  desired_state: "Estado heredero",
  configured_notification_group: "Grupo de Notificación Configurada",
  email_ids: "IDs de correo electrónico",
  logs: "troncos",
  unit_id: "ID de unidad",
  download_report: "Descargar Report",
  frequency: "Frecuencia",
  ip: "IP",
  edit_wifi: "Editar Wifi",
  details: "Detalles",
  connected_to: "Conectado a",
  software_version: "Versión del software",
  mac_address: "MAC Address",
  opc_client_enabled: "OPC Client Enabled",
  MQTT_bridge_enabled: "Puente MQTT Habilitado",
  hub_specification: "Especificación del Hub",
  hub_details: "Datos del Hub",
  restart_schedule: "Horario de reinicio",
  restart_device: "Dispositivo de reinicio",
  set_up_restart_time: "Configurar el tiempo de reiniciar",
  connectivity_logs: "Logros de conectividad",
  internal_cache_size: "Tamaño de la caché interna",
  default: "Default",
  configs: "Configs",
  processed: "Procesado",
  space_available: "Espacio disponible",
  kb: "KB",
  mb: "MB",
  last_ping: "Último Ping",
  toggle: "Toggle",
  configured_creds: "Credos configurados",
  psk: "PSK",
  set_value: "Valor de conjunto",
  time_current_time: "Tiempo (El tiempo actual se tomará si no se menciona)",
  Set: "Set",
  health: "Salud",
  port: "Puerto",
  mqtt_params: "Mqtt-Params",
  delete_device: "Eliminar el dispositivo",
  view_details: "Ver detalles",
  parameter_id: "Parámetro ID",
  email_address: "Dirección de correo electrónico",
  attached_triggers: "Activadores adjuntos",
  parameter_details: "Detalles del parámetro",
  enable_write: "Activar escritura",
  disable_write: "Desactivado",
  offset: "Offset",
  no_of_parameters: "No de parámetros",
  creation_time: "Tiempo de creación",
  not_configured: "No configurado",
  add_rule: "Add Rule",
  sl_no: "Sl No",
  targeted_by_value: "Objetivo por valor",
  configured_action: "Acción configurada",
  add_action: "Add Action",
  action_id: "ID de acción",
  delete_user: "Eliminar usuario",
  bit: "Bit",
  low: "Baja",
  high: "Alto",
  esg_scope_conversion: "ESG Conversión de Scope",
  batch_production_logs: "Producción de lotes Logs",
  esg_data_logs: "ESG Registros de datos",
  remove_from_dashboard: "Retirar de Dashboard",
  data_logs: "Registros de datos",
  select_device: "Seleccione el dispositivo",
  fetch_connectivity_logs: "Logros de conectividad",
  hub_internet_connectivity_logs: "Conectividad de Internet Hub Logs",
  modbus_gateways: "Modbus Gateways",
  device_switched_on: "El dispositivo se activa",
  device_switched_off: "El dispositivo se apaga",
  parameter_report: "Informe del parámetro",
  timeframed_specific_energy_consumption_report:
    "Informe de Consumo Específico de Energía",
  ebill_distribution_report: "E-Bill Distribution Report",
  root_user_management: "Gestión del usuario raíz",
  user_acces_management: "Gestión de acceso de usuarios",
  inference: "Inferencias",
  turn_off: "Date la vuelta",
  turn_on: "Date la vuelta",
  diagram_pallette: "Pallette de Diagrama",
  add_component: "Add Component",
  components: "Componentes",
  add_text: "Agregar texto",
  add_realtime_data: "Agregar datos en tiempo real",
  shapes: "Formas",
  lines: "Líneas",
  text_realtime_component: "Componente de texto en tiempo real",
  shapes_pallette: "Shapes Pallette",
  lines_pallette: "Líneas Pallette",
  click_choose: "Haga clic y elija las formas para añadir al SLD",
  choose_line: "Elija Línea para añadir a la SLD",
  choose_image: "Elija imagen",
  upload_image_to_add: "Subir imagen para añadir a la SLD",
  add_realtime_textdata: "Añadir datos de tiempo real o texto al SLD",
  update_user: "Actualizar usuario",
  add_watcher: "Añadir Watcher",
  premise_area: "Premise Area",
  create_computed_param: "crear computación Param",
  create_derived_param: "crear Param Derived",
  proceed_to_ship: "Procede a la nave",
  esg_data_collection: "ESG Data Collection",
  esg_scope_details: "ESG Detalles de la aplicación",
  select_custom_date_range: "Seleccione Fecha de rango",
  days_365: "365 días",
  days_180: "180 días",
  days_30: "30 días",
  create_user_for_SSO: "Crear usuario para SSO",
  add_widget: "Añadir Widget",
  add_machine_status_rules: "Add Machine Status Rules",
  add_trigger: "Añadir Trigger",
  add_logged_parameter: "Agregar parámetro Logged",
  create_mannual_param: "Crear Param masculino",
  add_opc_param: "Add OPC Param",
  create_write_block: "Crear bloque",
  all_parameters: "Todos los parámetros",
  edit_device: "Editar dispositivo",
  edit_modbus_device: "Editar dispositivo Modbus",
  edit_mqtt_device: "Editar dispositivo Mqtt",
  limit_entropy: "Limit Entropy",
  emails: "Emails",
  power_factor: "PowerFactor(PF)",
  tarrif_rate: "Tasa de Tarrif",
  apparent_power: "Potencia aparente",
  reactive_power: "Potencia reactiva",
  active_power: "Potencia activa",
  energy_usage_view_page: "Energy Usage View Page",
  switch_organisation: "Switch Organisation",
  wireless_creds: "Credos inalámbricos",
  latest: "Últimas",
  value_is: "Valor I",
  please_take_necessary_action: "Por favor, tome las medidas necesarias Medida",
  edit_role: "Función de edición",
  delete_role: "Suprímase la función",
  today: "Hoy",
  days_4: "4 días",
  due: "Due",
  move_to_done: "Muévete a Done",
  power_system_fault: "Power System Fault count (Última semana)",
  alarms: "Alarmas",
  electricity_consumption: "Consumo de electricidad (Fecha mínima)",
  average_demand: "Demanda media",
  water_use_intensity: "Intensidad del uso del agua (WUI)",
  average_water_cost: "Costo medio del agua (mestral)",
  water_use_efficiency: "Eficiencia del uso del agua (WUE)",
  batch_logs: "Batch Logs",
  edit_log: "Editar registro",
  delete_log: "Suprimir el registro",
  dates: "Fechas",
  log_details: "Detalles de la página",
  view: "Vista",
  scope1_emission: "Alcance 1 Emission",
  scope2_emission: "Alcance 2 Emission",
  lmtd: "LMTD",
  company_data: "Company Data",
  historian: "Historiador",
  from_optional: "De (opcional)",
  to_optional: "A (opcional)",
  trends: "Tendencias",
  generate_trend: "Generar tendencias",
  by: "Por",
  check_logs: "Registros de comprobación",
  workspaces: "Espacios de trabajo",
  types_of_nodes_connected: "Tipos de nodos conectados",
  types_of_modbus_device_connected: "Tipos de Dispositivos Modbus Conectados",
  no_of_registers: "Número de registros",
  write_register: "Regístrate",
  setting_view: "Vista de configuración",
  sign_up: "Regístrate",
  reset: "Reset",
  mobile_number: "Número de móvil",
  nebeskie_labs: "Laboratorios de Nebeskie",
  please_register_and_send_otp:
    "Por favor, introduzca su correo electrónico registrado Id y haga clic en Enviar OTP.",
  reset_password: "Reset Password",
  back_to_login: "Volver a iniciar sesión",
  otp: "OTP",
  send_otp: "Enviar OTP",
  org_alias: "Org Alias",
  please_stand_by: "Por favor",
  total_usage_this_month: "Total Usage Este mes",
  total_usage_last_month: "Uso total El mes pasado",
  generate_report_manually: "Generar informe manualmente",
  energy_distribution_detailed_report: "Energy Distribution Informe detallado",
  the_report_is_processing: "El informe está procesando",
  enter_active_energy_manually: "Ingrese la energía activa manualmente",
  include_others: "Incluir Otros",
  bill_amount: "Bill Amount",
  yes_delete_it: "Sí, Suprimirlo",
  create_write_param: "Crear escriba Param",
  report_list: "Lista de informes",
  plant_id: "ID de planta",
  plant_name: "Nombre de la planta",
  plant_address: "Dirección de planta",
  add_selected: "Añadir Seleccionado",
  clear_selection: "Selección clara",
  real_time_value: "Valor real",
  default_status: "Estado predeterminado",
  default_label: "Default Label",
  default_color_optional: "Color predeterminado opcional",
  rule_based_status: "Situación basada en las normas",
  show_label_only: "Show Label Only",
  show_unit: "Mostrar unidad",
  status_rules: "Reglas de Estado",
  configure: "Configuración",
  monthly_usage: "Uso mensual",
  meter_consumption_kwh: "Meter Consumption(kwh)",
  total_unit: "Total Unit",
  region: "Región",
  average_energy_consumed: "Energía media consumida",
  average_production: "Producción media",
  average_sec: "Promedio",
  total_production: "Producción total",
  total_energy_consumed: "Total Energy Consumed",
  max_sec: "Max SEC",
  device_1: "Dispositivo 1",
  device_parameter_1: "Parámetro de dispositivo 1",
  device_2: "Dispositivo 2",
  device_parameter_2: "Parámetro de dispositivo 2",
  create_param: "Crear Param",
  update_tags: "Actualización",
  remove_from_process: "Retirar del proceso",
  machine_parameters: "Parámetros de la máquina",
  energy: "Energy",
  power: "Poder",
  remove_from_featured: "Eliminar de Destacados",
  other_parameters: "Otros parámetros",
  add_to_featured: "Añadir a Destacados",
  logged_parameters: "Parámetros marcados",
  tags: "Etiquetas",
  machine_status_rules: "Reglas de estado de la máquina",
  connected_communication_devices: "Dispositivos de comunicación conectados",
  mqtt_devices: "Dispositivos Mqtt",
  modbus_devices: "Dispositivos Modbus",
  total_no_of_params: "Total No de Params",
  edge_devices: "Dispositivos de borde",
  machines_configured: "Máquinas configuradas",
  reports_configured: "Informes configurados",
  views_configured: "Vistas Configuradas",
  send_email_notification: "Enviar notificación de correo electrónico",
  enture_send_email_notification:
    "Let's Enture enviar email Notificación al grupo de usuarios seleccionado",
  agree_and_send: "Agree & Send",
  ebill: "Ebill",
  monthly_trend: "Tendencia mensual",
  param_view: "Param View",
  calories: "Calories",
  param_count_view: "Conde de Param Vista",
  created: "Creado",
  api_data_count_view: "API Cuenta de datos Vista",
  diesel_emissions: "Emisiones Diesel",
  eb_emissions: "Emisiones de EB",
  emissions_kg_co2: "Emisiones kg-Co2",
  kpis: "KPI",
  jan_23: "Jan-23",
  feb_23: "Feb-23",
  mar_23: "Mar-23",
  apr_23: "Apr-23",
  may_23: "May-23",
  jun_23: "Jun-23",
  lpg_emissions: "LPG Emissions",
  petrol_emissions: "Emisiones de petróleo",
  bullet_gauge: "Bullet Gauge",
  equipment: "Equipo",
  enter_no_of_machines: "No de máquinas",
  shift: "Cambio",
  upload: "Subir",
  image_upload: "Subir imagen",
  derived_parameter: "Parámetro derivado",
  value_type: "Valor_tipo",
  enable_entropy: "Enable Entropy",
  remove_decimal: "Remove Decimal",
  limiter_entropy: "Limiter Entropy",
  show_sku_id: "Show SKU ID",
  production_start_date: "Fecha de inicio de producción(Opcional)",
  production_start_time: "Inicio de producción Hora(Opcional)",
  time: "Hora",
  temperature_c: "Temperatura c)",
  set_temperature_c: "Set Temperatura (C)",
  no_of_people: "No hay gente",
  co2_level: "Nivel de Co2",
  aqi_optional: "AQI Opcional",
  delete_sheets: "Eliminar hojas de cálculo",
  root_login: "Root Login",
  uam_login: "UAM Iniciar sesión",
  login: "Iniciar sesión",
  add_oem_logo: "Agregar logo OEM",
  devices: "Dispositivos",
  derived_data_units: "Unidades Derivadas/Datos",
  roles: "Funciones",
  engineering: "Ingeniería",
  control_panel: "Panel de control",
  views: "Vistas",
  reports_analysis: "Informes y análisis",
  actions_triggers: 'Actions " Triggers',
  modules: "Módulos",
  processes: "Procesos",
  air_quality: "Calidad del aire",
  manufacturing: "Fabricación",
  esg: "ESG",
  task_managment: "Task Managment",
  sustainability: "Sostenibilidad",
  groups: "Grupos",
  log_book: "Libro de registro",
  monitor: "Monitor",
  enture_settings: "Ajustes de entrada",
  configuration: "Configuración",
  bar: "Bar",
  line: "Línea",
  s7_device: "Dispositivo S7",
  rack: "Rack",
  slot: "Ranura",
  db_number: "DB Número",
  byte_offset: "Byte Offset",
  bit_offset: "Bit Offset",
  data_length: "Longitud de los datos",
  val_type: "Tipo de valor",
  create_s7_device: "Crear dispositivo S7",
  scope_label: "Scopes",
  client_safety: "Seguridad del cliente",
  edit: "Editar",
  delete: "Suprimir",
  choose_param_to_monitor: "Elija Param To Monitor",
  rules: "Reglas",
  add_to_row: "Añadir a la fila",
  add_usage: "Añadir Usage",
  notifications: "Notificaciones",
  organisation_details: "Detalles de la organización",
  profile: "Perfil",
  user_access: "Acceso al usuario",
  admin: "Admin",
  "shifts": "turnos",
  "skus": "Unidades de mantenimiento de existencias",
  locations: "Ubicaciones",
  locations_event_types: "Ubicaciones Tipos de eventos",
  lists: "Liza",
  param_equations: "ecuaciones de parámetros",
  combo_params: "parámetros combinados",
  calculated_params: "parámetros calculados",
  "add_pie_TD_widget": "Agregar widget Pie TD",
  "edit_pie_TD_widget": "Editar widget Pie TD",
  "legend": "Legend",
  "pie": "Pie",
  "edit_bar_trend_widget": "Editar widget de tendencia de barra",
  "add_bar_trend_widget": "Agregar widget de tendencia de barra",
  "edit_horizantal_bar_trend_widget": "Editar widget de tendencia de barra horizontal",
  "add_horizantal_bar_trend_widget": "Agregar widget de tendencia de barra horizontal",
  "too_long": "Demasiado largo",
  "trigger_logs": "Trigger Logs",
  "create_trigger": "Crear Trigger",
  "right_click_to_configure": "Haga clic derecho para configurar",
  "width": "ancho",
  "height": "altura",
  "hide_value": "Ocultar valor",
  "edit_machine": "Editar máquina",
  "wait_time": "Tiempo de espera",
  "production_date": "Fecha de producción",
  "connectivity_status": "Estado de conectividad",
  "production_plans": "Planes de producción",
  "production_pending": "Producción pendiente",
  "pending_shipment": "Envio pendiente",
  "trace_logs": "Registros de seguimiento",
  "movement_events": "Eventos de movimiento por lotes",
  "entropy_threshold": "Umbral de entropía",
  "threshold_value": "Valor umbral",
  "pending_dispatch": "Envío pendiente",
  "dispatched": "Enviado",
  "dispatch_details": "Detalles de envío",
  "dispatch": "Enviar esto",
  "raw_materials": "Materias primas",
  "pour_qty": "Cantidad vertida",
  "summary": "Resumen",
  "optional": "Opcional",
  "param_mqtt_map": "Mapa de parámetros MQTT",
  "machine_status_mqtt_map": "Machine Status MQTT Map",
  "edit_trigger_count_table_widget": "Editar widget de tabla de recuento de activadores",
  "add_trigger_count_table_widget": "Agregar widget de tabla de recuento de activadores",
  "count": "La cuenta",
  "real_time_consumption": "Consumo en tiempo real",
  "search_view": "Vista de búsqueda",
  "multiple_entries": "Múltiples entradas",
  "combined_parameter": "Parámetro combinado",
  "background_color": "Color de fondo",
  "resize": "Cambiar tamaño",
  "remove": "eliminar",
  "select_views": "Seleccionar vistas",
  "difference": "diferencia",
  "sum": "suma",
  "set_param_type_for_machine": "establecer el tipo de parámetro para la máquina",
  "y1axis": "eje y1",
  "y2axis": "eje y2",
  "temperature_parameters": "Parámetros de temperatura",
  "area_name": "Nombre del área",
  "maintenance_due_on": "Mantenimiento debido el",
  "days_left": "quedan días",
  "enture_insights": "ideas de riesgo",
  "edit_logged_parameter": "Editar parámetro registrado",
  "reports": "Informes",
  "till_date_pie_chart": "Gráfico de pastel hasta la fecha",
  "till_date_bar_chart": "Gráfico de barras hasta la fecha",
  "tod_billing_widget": "Widget de facturación TOD",
  "bar_trend_widget": "Widget de tendencia de barras",
  "target_based_bar_trend_widget": "Widget de tendencia de barras basado en objetivos",
  "gauge_widget": "Widget de medidor",
  "consumption_pie_widget": "Widget de gráfico de pastel de consumo",
  "consumption_gauge_widget": "Widget de medidor de consumo",
  "angular_gauge_widget": "Widget de medidor angular",
  "table_widget": "Widget de tabla",
  "banner_widget": "Widget de banner",
  "param_equation_banner_widget": "Widget de banner de ecuación de parámetros",
  "shift_based_usage_table": "Tabla de uso basado en turnos",
  "bitwise_word_widget": "Widget de palabra por bits",
  "dynamic_log_widget": "Widget de registro dinámico",
  "machine_status_timeline_widget": "Widget de línea de tiempo de estado de la máquina",
  "process_or_machine_status_widget": "Widget de estado de proceso/máquina",
  "parameter_insights_widget": "Widget de información de parámetros",
  "heat_map": "Mapa de calor",
  "usage_score_card_widget": "Widget de tarjeta de puntuación de uso",
  "busbar_live_monitoring": "Monitoreo en vivo de barra colectora",
  "bullet_or_angular_guage_widget": "Widget de medidor angular o de bala",
  "dual_axis_bar_trend_widget": "Widget de tendencia de barras de doble eje",
  "trigger_count_table_widget": "Widget de tabla de conteo de activadores",
  "bar_trend_td_widget": "Widget de tendencia de barras TD",
  "horizantal_bar_trend_widget": "Widget de tendencia de barras horizontales",
  "parameter_status_based_usage_status": "Widget basado en el estado del parámetro",
  "choose_from_templates": "elegir de las plantillas",
  "shift_timeframed_trends":"Tendencias temporales basadas en turnos",
  "candlestick_trends":"Tendencias de velas",
  "free_ram":"Free RAM",



};
