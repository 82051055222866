<template>
    <v-container fluid @contextmenu="show" :dark="$store.getters.getColorPalette().isDark"
        :style="{ 'background-color': widget.background_color }">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false"
            :info="info" />
        <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog = true"
            v-if="$store.state.settingMode && edit">
            <v-list :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-list-item @click="update = true">
                    <v-list-item-title><strong>{{ $store.getters.getTextMap().edit }}</strong></v-list-item-title>
                    <v-list-item-icon>
                        <v-icon small>mdi-pencil</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="deleteWidget">
                    <v-list-item-title><strong>{{ $store.getters.getTextMap().delete }}
                        </strong></v-list-item-title>
                    <v-list-item-icon>
                        <v-icon color="red" small>mdi-trash-can</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ widget.label }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false">{{
                            $store.getters.getTextMap().close }}
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddHorizantalBarTrendWidget :widget="widget" v-on:close="update = false"
                    v-on:success="$emit('update')" />
            </v-card>
        </v-dialog>
    
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <div v-if="widget.meta && widget.meta.data" ref="vis"
            :style="[widget.height === 'fixed' ? { 'background-color': widget.background_color ? widget.background_color : $store.getters.getColorPalette().background2ColorCode, 'height': '150px', } : {}]">
        </div>
        <div v-else
            :style="[widget.height === 'fixed' ? { 'height': '175px', 'text-align': 'center' } : { 'text-align': 'center' }]">
            <v-icon small>mdi-hail</v-icon>
            <h5>{{ $store.getters.getTextMap().not_configured }}</h5>
        </div>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios';
import AddHorizantalBarTrendWidget from '@/components/crud_components/widgets/AddHorizantalBarTrendWidget'
import moment from 'moment';
import { io } from 'socket.io-client'
import Plotly from 'plotly.js'

export default {
    name: "HorizantalBarTrendWidget",
    components: {
        InfoAlert,
        AddHorizantalBarTrendWidget
    },
    created() {
        this.stream = io(this.$store.state.streamApi, { withCredentials: true, auth: { token: this.$store.state.jwt } })
    },
    beforeDestroy() {
        this.stream.close()
    },
    props: {
        widget: {
            type: Object,
            required: true
        },
        edit: {
            type: Boolean,
            required: true
        }
    },
    mounted() {
        console.log(this.widget);
        if (this.widget && this.widget.widget_id && this.widget.meta && this.widget.meta.data.length > 0) {
            this.createInputData()
        }

    },
    data() {
        return {
            showMenu: false,
            showDismissibleAlert: false,
            info: false,
            update: false,
            dialog: false,
            x: 0,
            y: 0,
            loading: false,
            inputData: [],
            graphData: {},
            multiParamGraphData: {},
            tempDataFinale: [],
            layout: {
                barmode: 'stack',
                font: { color: this.$store.getters.getColorPalette().accentCode },
                plot_bgcolor: this.widget.background_color ? this.widget.background_color : this.$store.getters.getColorPalette().background2ColorCode,
                paper_bgcolor: this.widget.background_color ? this.widget.background_color : this.$store.getters.getColorPalette().background2ColorCode,
                autosize: true,
                hoverlabel: { namelength: -1, },
                xaxis: {
                    tickangle: -12,
                    zeroline: false,
                    showgrid: true,
                    showticklabels: true,
                    color: this.$store.getters.getColorPalette().accentCode,
                },
                yaxis: {
                    range: [0, this.benchmarkYValue + 100],
                    showticklabels: true,
                    color: this.$store.getters.getColorPalette().accentCode,
                },
                uniformtext: {
                    "mode": "hide",
                    "minsize": 1
                },
            },
        }
    },
    methods: {
        show(e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            if (this.$store.state.settingMode) {
                this.$nextTick(() => {
                    this.showMenu = true
                })
            }
        },
        createInputData() {
            let temp = []
            for (let i of this.widget.meta.data) {
                temp.push({
                    id: i.param,
                    label: i.param_label,
                    idType: "parameter"
                })
            }
            this.inputData = temp
        },
        init() {
            this.getData()

        },
        unsubscribeParam(param) {
            this.stream.emit('removeParam', param)
        },
        deleteWidget() {
            this.loading = true
            axios.post(this.$store.state.api + 'deleteWidget', this.widget, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                .then(response => {
                    if (response.data.status === 'success') {
                        this.dialog = false
                        this.loading = false
                        this.$emit('update')
                    }
                }).catch(err => {
                    console.error(err)
                    this.loading = false
                });
        },

        getFromDate(date_format) {
            let from_date = moment();
            if (this.timeframe && this.disableTimeframe) {
                if (this.timeframe !== 'rtd') {
                    switch (this.timeframe) {
                        case '7':
                            from_date = date_format ? moment().subtract(7, 'days').format(date_format) : moment().subtract(7, 'days');
                            break;
                        case '30':
                            from_date = date_format ? moment().subtract(30, 'days').format(date_format) : moment().subtract(30, 'days');
                            break;
                        case '60':
                            from_date = date_format ? moment().subtract(60, 'days').format(date_format) : moment().subtract(60, 'days');
                            break;
                        case '90':
                            from_date = date_format ? moment().subtract(90, 'days').format(date_format) : moment().subtract(90, 'days');
                            break;
                        case '180':
                            from_date = date_format ? moment().subtract(180, 'days').format(date_format) : moment().subtract(180, 'days');
                            break;
                        case '365':
                            from_date = date_format ? moment().subtract(365, 'days').format(date_format) : moment().subtract(365, 'days');
                            break;
                        case 'yesterday':
                            from_date = date_format ? moment().subtract(1, 'days').format(date_format) : moment().subtract(1, 'days');
                            break;
                        case 'ytd':
                            from_date = date_format ? moment().startOf('year').format(date_format) : moment().startOf('year');
                            break;
                        default:
                            from_date = date_format ? moment().subtract(1, 'days').startOf('month').format(date_format) : moment().startOf('month');
                            break;
                    }
                } else {
                    return from_date
                }
            } else {
                if (this.widget && this.widget.widget_id && this.widget.meta && this.widget.meta.time_frame) {
                    if (this.widget.meta.time_frame !== 'rtd') {
                        switch (this.widget.meta.time_frame) {
                            case '7':
                                from_date = date_format ? moment().subtract(7, 'days').format(date_format) : moment().subtract(7, 'days');
                                break;
                            case '30':
                                from_date = date_format ? moment().subtract(30, 'days').format(date_format) : moment().subtract(30, 'days');
                                break;
                            case '60':
                                from_date = date_format ? moment().subtract(60, 'days').format(date_format) : moment().subtract(60, 'days');
                                break;
                            case '90':
                                from_date = date_format ? moment().subtract(90, 'days').format(date_format) : moment().subtract(90, 'days');
                                break;
                            case '180':
                                from_date = date_format ? moment().subtract(180, 'days').format(date_format) : moment().subtract(180, 'days');
                                break;
                            case '365':
                                from_date = date_format ? moment().subtract(365, 'days').format(date_format) : moment().subtract(365, 'days');
                                break;
                            case 'yesterday':
                                from_date = date_format ? moment().subtract(1, 'days').format(date_format) : moment().subtract(1, 'days');
                                break;
                            case 'ytd':
                                from_date = date_format ? moment().startOf('year').format(date_format) : moment().startOf('year');
                                break;
                            default:
                                from_date = date_format ? moment().subtract(1, 'days').startOf('month').format(date_format) : moment().startOf('month');
                                break;
                        }
                    } else {
                        return from_date
                    }
                }
            }
            return from_date
        },

        createMultipleParamChart() {
            this.multiParamsinit()
            if (this.widget && this.widget.meta) {
                this.layout.yaxis['title'] = this.widget.meta.yaxis_label
                for (let i of this.tempDataFinale) {
                    if (!(i.param in this.multiParamGraphData)) {
                        let type = this.graphType == 1 ? 'line' : 'bar'
                        this.multiParamGraphData[i.param] = {
                            x: [],
                            y: [],
                            name: i.label,
                            orientation:'h',
                            type: type,
                            opacity: 0.9,
                            marker: {
                                line: {
                                    color: this.$store.getters.getColorPalette().accentCode,
                                    width: 1
                                },
                            },
                        }
                    }
                    if (this.widget.meta.time_frame != 'rtd') {
                        for (let j of i.data) {
                            this.multiParamGraphData[i.param]['y'].push(moment(Number(j.date), 'YYYYMMDD').format('YYYY-MM-DD'))
                            this.multiParamGraphData[i.param]['x'].push(j.usage)
                        }
                    }
                }

                this.addIdealValueToGraph()
            }
        },

        addIdealValueToGraph(){
      this.layout['title']= this.widget.label
      if(this.widget.meta && this.widget.meta.legend){
        this.layout['showlegend']=true
      }
      if(this.widget.meta && this.widget.meta.barmode){
        this.layout['barmode']=this.widget.meta.barmode
      }else{
        this.layout['barmode']='group'
      }
      let type=this.graphType==1?'line':'bar'
      for(let i in this.graphData){
        this.graphData[i]['type']=type
      }
      if (this.widget.meta.showIdeal ) {
        let from_date;
        if(this.timeframe && this.disableTimeframe){
          switch (this.timeframe) {
                
                case '7':
                  from_date = moment().subtract(7,'days');
                  break;
                  
                case '30':
                  from_date = moment().subtract(30,'days');
                  break;
                  
                  case '60':
                    from_date = moment().subtract(60,'days');
                    break;
                    
                    case '90':
                      from_date = moment().subtract(90,'days');
                      break;
                      
                      case '180':
                        from_date = moment().subtract(180,'days');
                        break;
                        
                        case '365':
                          from_date = moment().subtract(365,'days');
                      break;
              
                    case 'yesterday':
                    from_date = moment().subtract(1,'days');
                      break;
                    case 'ytd':
                      from_date = moment().startOf('year');
                      break;

                    default:
                      from_date = moment().subtract(1, 'days').startOf('month');
                      break;
                  }
        }else{
          switch (this.widget.meta.time_frame) {
            case 'ytd':
              from_date = moment().startOf('year');
              break;
            case 'mtd':
              from_date = moment().subtract(1, 'days').startOf('month');
              break;
            case 'yesterday':
            from_date = moment().subtract(1, 'days');
              break;
            case 'rtd':
              from_date=moment().startOf('day');
              break;
            default:
              from_date = moment().subtract(parseInt(this.widget.meta.time_frame), 'days');
          }
        }
        this.graphData['benchmarkLine'] = {
          type: 'line',
          x: [from_date.format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')],
          y: [this.widget.meta.idealValue ? this.widget.meta.idealValue : 0, this.widget.meta.idealValue ? this.widget.meta.idealValue : 0],
          line: {
            color: 'red',
            width: 2,
            dash: 'dash',
          },
          showlegend: true,
          name: this.widget.meta.idealLabel ? this.widget.meta.idealLabel : '',
        };
      }
      for(let k of Object.keys(this.multiParamGraphData)){
        this.graphData[k]=this.multiParamGraphData[k]
        this.graphData[k]['orientation']='h'
      }

      console.log(this.graphData);
      

      Plotly.newPlot(this.$refs.vis, Object.values(this.graphData), this.layout, {displaylogo: false});
    },

        multiParamsinit() {
            if (this.widget && this.widget.meta && this.widget.meta.data && this.widget.meta.time_frame && this.inputData) {
                let type = this.graphType == 1 ? 'line' : 'bar'
                this.multiParamGraphData = {}
                for (let i of this.inputData) {
                    this.multiParamGraphData[i.label] = {
                        x: [],
                        y: [],
                        name: i.label,
                        type: type,
                        opacity: 0.9,
                        showlegend: true,
                        marker: {
                            line: {
                                color: this.$store.getters.getColorPalette().accentCode,
                                width: 0.3
                            },
                        }
                    }
                    if (this.widget.meta && this.widget.meta.time_frame == 'rtd') {
                        if (Array.isArray(i.id)) {
                            for (let p of i.id) {
                                this.stream.emit('getParam', p)
                            }
                        } else {
                            this.stream.emit('getParam', i.id)
                        }
                    } else {
                        if (Array.isArray(i.id)) {
                            for (let p of i.id) {
                                this.unsubscribeParam(p)
                            }
                        } else {
                            this.unsubscribeParam(i.id)
                        }
                    }
                }
            }
        },
        async getData() {
            console.log("getting data...");
            try {
                if (this.widget.height === 'fixed') {
                    this.layout.height = 160;
                    this.layout.font = { size: 8, color: this.$store.getters.getColorPalette().accentCode };
                    this.layout.margin = { t: 40, b: 40, l: 40, r: 40 };
                }
                this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray;
                this.graphData = {};
                this.multiParamGraphData = {};
                let from_date = moment().subtract(1, 'days');
                from_date = this.getFromDate('YYYYMMDD');
                let to_date = moment().format('YYYYMMDD');
                if (this.widget && this.widget.meta && this.widget.meta.data && this.inputData && this.inputData.length > 0) {
                    this.tempDataFinale = [];
                    for (let i of this.inputData) {
                        let payload = {
                            from_date: from_date,
                            to_date: to_date,
                            timeframe: this.widget.meta.time_range ? this.widget.meta.time_range : 'day'
                        };
                        if (Array.isArray(i.id)) {
                            payload['parameters'] = i.id;
                        } else {
                            payload['parameters'] = [i.id];
                        }

                        if (payload.parameters.length > 1) {
                            let response = await axios.post(this.$store.state.api + 'getParamGroupedUsageDataByTimeframe', payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } });
                            if (response.data.status == 'success') {
                                this.loading = false;
                                let temp = {};
                                let responseData = response.data.data;

                                temp['param'] = i.label;
                                let usageDataMap = new Map();

                                // Combine usage for duplicate dates
                                for (let data of responseData) {
                                    if (usageDataMap.has(data.date)) {
                                        usageDataMap.get(data.date).usage += data.usage;
                                    } else {
                                        usageDataMap.set(data.date, { ...data });
                                    }
                                }

                                temp['data'] = Array.from(usageDataMap.values());
                                this.tempDataFinale.push(temp);
                            } else {
                                this.loading = false;
                                this.info = response.data.msg;
                                this.showDismissibleAlert = true;
                            }
                        } else {
                            let response = await axios.post(this.$store.state.api + 'getParamGroupedUsageDataByTimeframe', payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } });
                            if (response.data.status === 'success') {
                                this.loading = false;
                                let temp = {};
                                temp['param'] = i.label;
                                temp['data'] = response.data.data;
                                this.tempDataFinale.push(temp);
                            } else {
                                this.loading = false;
                                this.info = response.data.msg;
                                this.showDismissibleAlert = true;
                            }
                        }
                    }

                    this.createMultipleParamChart();
                    this.loading = false;
                }

            } catch (err) {
                this.loading = false;
                this.info = err;
                this.showDismissibleAlert = true;
            }
        }
    },
    watch: {
        widget() {
            this.createInputData()
        },

        inputData() {
            this.init()
        }

    }
}
</script>

<style></style>