<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="mt-1 pa-3">
            <v-row no-gutters>
                <v-card-title><strong>{{$store.getters.getTextMap().parameter_details}}</strong></v-card-title>
                <v-spacer></v-spacer>
                <v-col v-if="coil && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','write'])"  cols="auto" class="d-flex" align="right" align-self=center>
                    <v-btn class="mx-1" :color="$store.getters.getColorPalette().deletebtnColor" small text outlined :dark="$store.getters.getColorPalette().isDark"  @click="writeCoil(0)">{{$store.getters.getTextMap().off}}</v-btn>
                    <v-btn :dark="$store.getters.getColorPalette().isDark" small text outlined color="green" @click="writeCoil(1)">{{$store.getters.getTextMap().on}}</v-btn>
                </v-col>
                <v-col v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','write'])"  cols="auto" class="d-flex" align="right" align-self=center>
                    <v-btn class="mx-1" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="enableWrite">{{$store.getters.getTextMap().enable_write}}
                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined @click="disableWrite">{{$store.getters.getTextMap().disable_write}}
                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-pencil-off-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
            <v-row class="pa-3" >
                <v-col  align="center" align-self="center">
                    <h4 class="count-header">{{$store.getters.getTextMap().id}}
</h4>
                    <span class="count">{{param.param_id}}</span>
                </v-col>
                <v-divider vertical/>
                <v-col align="center" align-self="center">
                    <h4  class="count-header">{{$store.getters.getTextMap().name}}
</h4>
                    <span class="count">{{param.name ?param.name : param.param_id}}</span>
                </v-col>
                <v-divider vertical/>
                <v-col align="center" align-self="center">
                    <h4  class="count-header">{{$store.getters.getTextMap().value}}
</h4>
                    <span class="count">{{val}}</span>{{param.unit}}
                </v-col>
            </v-row>
            <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
        </v-card>


        <v-col align-self="center" align="right"
            v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
            <AttachTriggerToParamModal :parameter="param" />
        </v-col>

        <v-card v-if="attachedTriggers && attachedTriggers.length > 0"
            :style="{ 'border-radius': '5px', 'border': '1px solid' + $store.getters.getColorPalette().panelBorderColorCode }"
            flat :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().background2ColorCode" class="mt-1 pa-3">
            <v-row no-gutters>
                <v-col>
                    <v-card-title><strong>{{$store.getters.getTextMap().attached_triggers}}
</strong></v-card-title>
                </v-col>
                <v-col align-self="center" align="right" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
                    <AttachTriggerToParamModal :parameter="param" />
                </v-col>
            </v-row>
            <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
            <v-row no-gutters>
                <v-col v-if="loading">
                    <v-progress-circular indeterminate v-if="loading" color="primary"></v-progress-circular>
                </v-col>
                <v-col v-if="!loading">
                    <v-chip
                        class="ma-2"
                        close
                        @click:close="removeTrigger(trigger)" 
                        color="indigo"
                        text-color="white" v-for="trigger in attachedTriggers"
                        :key="trigger.trigger_id">{{$store.getters.getTriggerById(trigger.trigger_id).name}}
                    </v-chip>
                </v-col>
            </v-row>
        </v-card>
        <v-card  v-if="param && param.writable" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="mt-1 pa-0">
            <v-row no-gutters>
                <v-col cols="12">
                    <ParamWritePanel :isCoil="coil" :param_id="param.param_id" v-on:success="$emit('update')"/>
                </v-col>
            </v-row>
        </v-card>
        <v-card v-if="dataType=='Word'" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="mt-1 pa-0">
            <v-row>
                <v-col>
                    <WordParamDetails :parameter="param" :tableData="streamData" />
                </v-col>
            </v-row>
        </v-card>
        <v-card v-if="streamData" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="mt-1 pa-3">
            <v-row>
                <v-col>
                    <SingleLineGraphByEpoch :label="name" :graphData="streamData" dataKey="data" />
                </v-col>
            </v-row>
        </v-card>
        <v-card  v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  class="mt-1 pa-0">
            <v-row no-gutters>
                <v-col>
                    <ParamMQTTMap :param_id="param_id" />
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import AttachTriggerToParamModal from '@/components/modals/AttachTriggerToParamModal'
import WordParamDetails from '@/components/panels/WordParamDetails'
import ParamMQTTMap from '@/components/crud_components/ParamMQTTMap'
import ParamWritePanel from '@/components/crud_components/control/ParamWritePanel'
import SingleLineGraphByEpoch from '@/components/graphs/SingleLineGraphByEpoch'
export default {
    name:'ParamDetails',
    props:['param_id','val','streamData'],
    components:{
      AttachTriggerToParamModal,
      WordParamDetails,
      InfoAlert,
      ParamMQTTMap,
      ParamWritePanel,
      SingleLineGraphByEpoch
    },
    data(){
      return {
        info:'Error',
        loading:false,
        showDismissibleAlert:false,
        param:{},
      }
    },
    mounted(){
        if(this.param_id){
            this.getParmeterDetails()
        }
    },
    computed: {
    name(){
      return this.param.name ? this.param.name : this.param.unit
    },
    unit(){
      if(this.param.unit){
        return this.param.unit
      }else{
        return ''
      }
    },
    attachedTriggers(){
      let triggers=this.$store.getters.getTriggerIDsByParameter(this.param.param_id)
      if(triggers){
        return triggers
      }else{
        return []
      }
    },
    dataType(){
      if(!isNaN(this.param.data_type)){
        return this.$store.getters.getDataTypeById(this.param.data_type)?.type
      }
      return null
    },
    coil(){
      if(this.param && this.param.command==1) return true
      return false
    }
  },
    methods: {
        getParmeterDetails(){
            axios.post(this.$store.state.api+'getParameterByID',{param_id:this.param_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status==='success'){
                this.loading=false
                this.param=response.data.param
            }else{
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
                this.loading=false
            }})
            .catch(err=>{console.log(err)
                this.info=err
                this.loading=false
                this.showDismissibleAlert=true
            });
        },
        enableWrite(){
            let payload={
                    param_id:this.param_id
                }
            this.loading=true
            axios.post(this.$store.state.api+'enableWrite',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status==='success'){
                this.getParmeterDetails()
                this.loading=false
                this.info="Write Enabled"
                this.showDismissibleAlert=true 
            }else{
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
                this.loading=false
            }})
            .catch(err=>{console.log(err)
                this.info=err
                this.loading=false
                this.showDismissibleAlert=true
            });
        },
        disableWrite(){
            let payload={
                    param_id:this.param_id
                }
            this.loading=true
            axios.post(this.$store.state.api+'disableWrite',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status==='success'){
                this.getParmeterDetails()
                this.loading=false
                this.info="Write Disabled"
                this.showDismissibleAlert=true
            }else{
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
                this.loading=false
            }})
            .catch(err=>{console.log(err)
                this.info=err
                this.loading=false
                this.showDismissibleAlert=true
            });
        },
        writeCoil(state){
            let payload={
                value:state,
                param_id:this.param.param_id
                } 
            this.loading=true
            axios.post(this.$store.state.api+'writeParam',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
            if(response.data.status==='success'){
                this.loading=false
                this.info="Command Sent"
                this.showDismissibleAlert=true
            }else{
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
                this.loading=false
            }})
            .catch(err=>{console.log(err)
                this.info=err
                this.loading=false
                this.showDismissibleAlert=true
            });
        },
        removeTrigger(trigger){
            this.loading=true
            axios.post(this.$store.state.api+'removeTriggerFromParam',trigger,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status==='success'){
                this.$store.dispatch('refreshParamTriggerMappings')
                this.loading=false
                this.info="Trigger removed"
                this.showDismissibleAlert=true
            }else{
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
                this.loading=false
            }})
            .catch(err=>{console.log(err)
                this.info=err
                this.loading=false
                this.showDismissibleAlert=true
            });
        },
        cancel(){
        this.showDialog=false
        },
        confirm(){
        this.showDialog=false
        }
    },
    watch:{
        param_id(){
            if(this.param_id){
                this.getParmeterDetails()
            }
        }
    }
}
</script>